/*
 *
 * Setting: Reserlution;
 *
 */

$d-width-sl: 2000;
$d-width-content__max: 1200;
$d-width-xl: 1500;
$d-width-lg: 1440;
$d-width-lg__bootstrap: 1200;
$d-width-md: 1024;
$d-width-content__min: 992;
$d-width-md__bootstrap: 992;
$d-width-sm__bootstrap: 768;
$m-width-contnet__max: 768;
$m-width-xs__bootstrap: 576;
$m-width-contnet__min: 360;
$d-chrome-max: 1280;
$d-chrome-min: $d-width-md__bootstrap;
// ────────────────────────────────────────────────────── I ──────────
//   :::::: D E S K T O P  992px ~ 1920px
// ────────────────────────────────────────────────────────────────
//
$desktop-super-large: $d-width-sl*1px;
$desktop-content-max: $d-width-content__max*1px;
$desktop-content-max__chrome: $d-chrome-max*1px;
$desktop-extra-large: $d-width-xl*1px;
$desktop-extra-lg: $d-width-lg*1px;
$desktop-large: $d-width-lg__bootstrap*1px;
$desktop-large-width__bootstrap: ($d-width-lg__bootstrap - 0.02)*1px;
$desktop-small: $d-width-md__bootstrap*1px;
$desktop-content-min: $d-width-content__min*1px;
$desktop-content-min__chrome: $d-chrome-min*1px;
$desktop-content-min__bootstrap: $desktop-small;
// ────────────────────────────────────────────────────────────────
//   :::::: T A B L E T  768px ~ 991.999px
// ────────────────────────────────────────────────────────────────
//
$laptop-max-width: ($d-width-lg__bootstrap - 0.02)*1px;
$laptop-min-width: $d-width-md__bootstrap*1px; //
// ────────────────────────────────────────────────────────────────
//   :::::: T A B L E T  768px ~ 991.999px
// ────────────────────────────────────────────────────────────────
//
$tablet-extra-md: $d-width-md*1px;
$tablet-max-width: ($d-width-md__bootstrap - 0.02)*1px;
$tablet-min-width: $d-width-sm__bootstrap*1px; //
// ────────────────────────────────────────────────────────────────
//   :::::: M O B I L E  360px ~ 767.999px
// ────────────────────────────────────────────────────────────────
//
$mobile-max-width__bootstrap: ($d-width-sm__bootstrap - 0.02)*1px;
$mobile-max-width: $m-width-contnet__max*1px;
$mobile-min-width: $m-width-contnet__min*1px;

// ────────────────────────────────────────────────────────────────
//   :::::: M O B I L E  0px ~ 575.999px
// ────────────────────────────────────────────────────────────────
//
$m-max-width__bootstrap: ($m-width-xs__bootstrap - 0.02)*1px;
/*
 *
 * Setting: Colors;
 *
 */
// ────────────────────────────────────────────────────────────────
//   :::::: Overwrite Bootstrap colors
// ────────────────────────────────────────────────────────────────
//
$primary: #00b5e2;
$spacer: 1.5rem;
$carousel-control-icon-width: 2rem;
// ────────────────────────────────────────────────────────────────
//   :::::: Site colors
// ────────────────────────────────────────────────────────────────
//
$col-text: #4a4a4a;
$col-link: #9b9b9b;
$col-link-hover: #7b7b7b;
$col-vive-blue: #00b5e2;

$col-htc-green: #4bd6b8;
$col-htc-green-hover: #30b397;


$d-section-gutter: 80px;
$d-section-extra-gutter: 120px;
$m-section-gutter: 50;
$m-section-extra-gutter: 80;

$fa-font-path: 'webfonts';

//font

$font-basic-family: 'Montserrat', 'Noto Sans TC', sans-serif;
$line-height-basic: normal;

$font-basic-size: 14px;
$font-basic-t-size: 14px;
$font-basic-m-size: $font-basic-t-size;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;

$font-size-1: 40px;
$font-size-2: 30px;
$font-size-3: 24px;
$font-size-4: 18px;
$font-size-5: 16px;
$font-size-6: $font-basic-size;

// Font size for tablet/mobile
$font-size-t-1: 24px;
$font-size-t-2: 24px;
$font-size-t-3: 18px;
$font-size-t-4: 16px;
$font-size-t-5: 16px;
$font-size-t-6: $font-basic-t-size;
