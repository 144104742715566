@mixin font-style-1($line-height: $line-height-basic) {
  font-size: $font-size-1;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-1;
  }
}

@mixin font-style-2($line-height: $line-height-basic) {
  font-size: $font-size-2;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-2;
  }
}

@mixin font-style-3($line-height: $line-height-basic) {
  font-size: $font-size-3;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-3;
  }
}

@mixin font-style-4($line-height: $line-height-basic) {
  font-size: $font-size-4;
  font-weight: $font-weight-medium;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-4;
  }
}

@mixin font-style-5($line-height: $line-height-basic) {
  font-size: $font-size-5;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-5;
  }
}

@mixin font-style-6($line-height: $line-height-basic) {
  font-size: $font-size-6;
  font-weight: $font-weight-regular;
  line-height: $line-height;
  @media (max-width: ($tablet-max-width)){
    font-size: $font-size-t-6;
  }
}

@function reset-mobile-size-by-vw($number){
  @return $number/$m-width-contnet__min*100vw;
}

@function reset-tablet-size-by-vw($number){
  @return $number/$d-width-sm__bootstrap*100vw;
}
@function reset-laptop-size-by-vw($number){
  @return $number/$d-width-lg__bootstrap*100vw;
}


@mixin animate-actived{
  bottom: calc((1440px - 100vh)/3*2 );
  @media screen and (min-height: 1441px){
    bottom: 0;
  }
  @media screen and (max-width: ($desktop-extra-lg)) {
    bottom: calc(((900px - 100vh)/2));
  }
  @media screen and (max-width: ($desktop-extra-lg)) and (min-height: 901px) {
    bottom: 0;
  }
  @include media-breakpoint-down(md){
    bottom: calc(  (1323px- 100vh)/4*3);
  }
  @media screen and (max-width: 991.98px) and (min-height: 1324px) {
    bottom: 0;
  }
  @include media-breakpoint-down(sm){
    // bottom: calc((1280px - 100vh));
    bottom: calc( 1280px -  var(--vh, 1vh) * 100);
  }
  @media screen and (max-width: 767.98px) and (min-height: 1281px) {
    bottom: 0;
  }
}

@mixin animate-after-actived{
  transform: translate(-50%, -100%);
  @media screen and (min-height:1441px) {
    transform: translate(-50%, -100vh);
  }
  @media screen and (max-width: ($desktop-extra-lg)) {
    transform: translate(-50%, -100%);
  }
  @media screen and (max-width: ($desktop-extra-lg)) and (min-height: 901px) {
    transform: translate(-50%, -100vh);
  }
  @media screen and (max-width: 991.98px) {
    transform: translate(-50%, -100%);
  }
  @media screen and (max-width: 991.98px) and (min-height: 1324px) {
    transform: translate(-50%, -100vh);
  }
  @media screen and (max-width: 767.98px) {
    transform: translate(-50%, -100%);
  }
  @media screen and (max-width: 767.98px) and (min-height: 1281px) {
    transform: translate(-50%, -100vh);
  }
}


@mixin animate-after-actived-text{
  top: 80vh;
  @media screen and (min-height:1441px) {
    top: 80%;
  }
  @media screen and (max-width: ($desktop-extra-lg)){
    top: 80vh;
  }
  @media screen and (max-width: ($desktop-extra-lg)) and (min-height: 901px) {
    top: 80%;
  }
  @media screen and (max-width: 991.98px){
    top: 80vh;
  }
  @media screen and (max-width: 991.98px) and (min-height: 1324px) {
    top: 80%;
  }

  @media screen and (max-width: 767.98px){
    top: auto;
    // bottom: calc(1280px - 100vh);
    // transform: translate(-50%,0);
    // bottom: -100vh;
    bottom: calc( 0 - (var(--vh, 1vh) * 100));
    transform: translate(-50%,-1280px);
  }
  @media screen and (max-width: 767.98px) and (min-height: 1281px) {
    top: 80%;
    transform: translate(-50%,-50%);
  }
  @media screen and (orientation:landscape) and (max-width: 767.98px) {
    bottom: calc( 0 - (var(--vh, 1vh) * 100));
  }
}
