body {
  @include font-style-6();
}
h1,
.h1,
.txt-style-1 {
  @include font-style-1();
}

h2,
.h2,
.txt-style-2 {
  @include font-style-2();
}

h3,
.h3,
.txt-style-3 {
  @include font-style-3();
}

h4,
.h4,
.txt-style-4{
  @include font-style-4();
}

h5,
.h5,
.txt-style-5{
  @include font-style-5();
}

h6,
.h6,
.txt-style-6{
  @include font-style-6();
}

p,a{
  @include font-style-6();
  color: $col-text;
}
